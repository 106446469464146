import '../stylesheets/main.scss';

import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

// Use what-input to track input method
import 'what-input';

// Shims
import './shims/object-fit';

import initAtomCountdown from '../components/atoms/countdown/javascripts';
import initAtomInput from '../components/atoms/input/javascripts';
import initAtomSgUser from '../components/atoms/sg-user/javascripts';

import initMoleculeAccountActions from '../components/molecules/account-actions/javascripts';
import initMoleculeCallout from '../components/molecules/callout/javascripts';
import initMoleculeContentVideo from '../components/molecules/content-video/javascripts';
import initMoleculeEventTeaser from '../components/molecules/event-teaser/javascripts';
import initMoleculeLivestreamTeaser from '../components/molecules/livestream-teaser/javascripts';
import initMoleculeLoginAccount from '../components/molecules/login-account/javascripts';
import initMoleculeMixedCopy from '../components/molecules/mixed-copy/javascripts';
import initMoleculeSocialWall from '../components/molecules/social-wall/javascripts';
import initMoleculePWATeaser from '../components/molecules/pwa-teaser/javascripts';
import initMoleculeTagList from '../components/molecules/tag-list/javascripts';
import initMoleculeTeaser from '../components/molecules/teaser/javascripts';
import initMoleculeGallery from '../components/molecules/gallery/javascripts';
import initMoleculeGallerySlider from '../components/molecules/gallery-slider/javascripts';
import initMoleculeTeaserStatuslane from '../components/molecules/teaser-statuslane/javascripts';
import initMoleculeFeedbackButton from '../components/molecules/feedback-btn/javascripts';

import initOrganismAnchorLinks from '../components/organisms/anchor-links/javascripts';
import initOrganismForm from '../components/organisms/form/javascripts';
import initOrganismHeader from '../components/organisms/header/javascripts';
import initOrganismIntroTeaser from '../components/organisms/intro-teaser/javascripts';
import '../components/organisms/lightbox/javascripts';
import initOrganismModal from '../components/organisms/modal/javascripts';
import initOrganismFooter from '../components/organisms/footer/javascripts';
import initOrganismHighlightVideoTeaser from '../components/organisms/highlight-video-teaser/javascripts';
import initOrganismMobilePrompt from '../components/organisms/mobile-prompt/javascripts';
import initOrganismTeaserLane from '../components/organisms/teaser-lane/javascripts';
import initOrganismTeaserGrid from '../components/organisms/teaser-grid/javascripts';
import initOrganismTeaserSlider from '../components/organisms/teaser-slider/teaser-slider';
import initOrganismTicketStage from '../components/organisms/ticket-stage/javascripts';
import initOrganismStageStreetGigs from '../components/organisms/stage-street-gigs/javascripts';
import initOrganismTicketList from '../components/organisms/ticket-list/javascripts';
import initOrganismStage from '../components/organisms/stage/javascripts/stage';
import initOrganismUprightStage from '../components/organisms/stage-upright/javascripts';
import initOrganismVideoPlayer from '../components/organisms/video-player/javascripts';
import initOrganismVideoStory from '../components/organisms/video-story/javascripts';
import initOrganismVideoBanner from '../components/organisms/video-banner/javascripts';
import initOrganismExternalLinkHandler from '../components/organisms/external-link-handler/javascripts';
import initPwaHandler from '../components/organisms/pwa-handler/javascripts';
import initOrganismSearchBar from '../components/organisms/search-bar/javascripts';
import initOrganismTeaserTabs from '../components/organisms/teaser-tabs/javascripts';
import initOrganismLandingStage from '../components/organisms/landing-stage/javascripts';
import initOrganismShareButton from '../components/organisms/share-button/javascripts';
import initOrganismLineUp from '../components/organisms/line-up/javascripts';

Sentry.init({
  enabled: window.SENTRY_ENABLED,

  dsn: 'https://330f5385df154f40b15d752e36ef623c@report.magentamusik.de/3',

  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: window.SENTRY_TRACES_SAMPLE_RATE,

  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    /global code/,

    'ChunkLoadError',

    // Firefox message when an extension tries to modify a no-longer-existing DOM node
    // See https://blog.mozilla.org/addons/2012/09/12/what-does-cant-access-dead-object-mean/
    /can't access dead object/
  ],

  denyUrls: [
    // https://googleads.g.doubleclick.net/
    /doubleclick\.net/i,
    // https://www.googletagmanager.com/gtag/js
    /googletagmanager\.com/i,
    // https://w.usabilla.com/
    /d6tizftlrpuof\.cloudfront\.net/i,
    /usabilla\.com/i,

    // Browser extensions
    /extensions\//i,
    /^chrome(-extension)?:\/\//i,
    /^safari(-web)?-extension:\/\//i,
    /^moz-extension:\/\//i,
    /webkit-masked-url/i // @webkit-masked-url(://hidden/)
  ],

  beforeSend(event) {
    // Only report errors when consent for analytics is given
    // We check the consent state on each event as the user may change
    // their consent during the runtime of the website.
    // See MM360-1286
    if (
      window.utag &&
      window.utag.gdpr.getSelectedCategories().includes('analytics')
    ) {
      return event;
    }
    return null;
  }
});

const initSw = async () => {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const { Workbox } = await import('workbox-window');

    const wb = new Workbox('/service-worker.js');
    wb.register();
  }
};
initSw();

function enhancedDomReady(init) {
  window.addEventListener('DOMContentLoaded', init);
  window.addEventListener('reload', init);
}

enhancedDomReady(() => {
  initPwaHandler();

  initAtomCountdown();
  initAtomInput();
  initAtomSgUser();

  initMoleculeAccountActions();
  initMoleculeCallout();
  initMoleculeContentVideo();
  initMoleculeEventTeaser();
  initMoleculeLivestreamTeaser();
  initMoleculeLoginAccount();
  initMoleculeMixedCopy();
  initMoleculeSocialWall();
  initMoleculeTagList();
  initMoleculeTeaser();
  initMoleculeGallery();
  initMoleculeGallerySlider();
  initMoleculeTeaserStatuslane();
  initMoleculePWATeaser();
  initMoleculeFeedbackButton();

  initOrganismAnchorLinks();
  initOrganismForm();
  initOrganismHeader();
  initOrganismIntroTeaser();
  initOrganismFooter();
  initOrganismHighlightVideoTeaser();
  initOrganismModal();
  initOrganismMobilePrompt();
  initOrganismTeaserLane();
  initOrganismTeaserGrid();
  initOrganismTeaserSlider();
  initOrganismTicketStage();
  initOrganismTicketList();
  initOrganismStage();
  initOrganismUprightStage();
  initOrganismStageStreetGigs();
  initOrganismTeaserTabs();
  initOrganismVideoPlayer();
  initOrganismVideoBanner();
  initOrganismVideoStory();
  initOrganismExternalLinkHandler();
  initOrganismSearchBar();
  initOrganismLandingStage();
  initOrganismShareButton();
  initOrganismLineUp();
});

// All links with target="_blank" attribute should have an attribute rel="noopener". This has performance and security reasons https://developers.google.com/web/tools/lighthouse/audits/noopener
const externalLinks = document.querySelectorAll("[target='_blank']");
if (externalLinks) {
  externalLinks.forEach((link) => {
    link.setAttribute('rel', 'noopener');
  });
}

/**
 * Open cookie consent preferences on elements with a special attribute
 *
 * MM360-1270
 */
if (window.utag) {
  const $constentPreferencesLink = document.querySelectorAll(
    '[data-open-consent-preferences]'
  );
  $constentPreferencesLink.forEach(($link) => {
    $link.addEventListener('click', (ev) => {
      ev.preventDefault();

      window.utag.gdpr.showConsentPreferences();
    });
  });
}

/**
 * Open cookie consent preferences when #open-consent-preferences is set
 *
 * MM360-1525
 */
if (window.utag) {
  const checkAndShowPreferences = () => {
    if (window.location.hash === '#open-consent-preferences') {
      window.utag.gdpr.showConsentPreferences();
    }
  };

  window.addEventListener('hashchange', () => checkAndShowPreferences());
  checkAndShowPreferences();
}

/**
 * Store the visible screen height, i.e. what should be 100vh, in a CSS variable
 *
 * In iOS Safari, when using `height: 100vh;`, content is placed behind
 * the bottom address and navigation bars. To only have content in the
 * visible screen estate, we measure the visible area using JS and put
 * the pixel value into a CSS variable.
 *
 * @example
 *   height: var(--app-height, 100vh); // full height
 *
 * @see {https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8}
 */
const onWindowResize = () => {
  document.documentElement.style.setProperty(
    '--app-height',
    `${window.innerHeight}px`
  );
};
window.addEventListener('resize', onWindowResize);
onWindowResize();
